import React from "react"
import "./ImportBox.css"
import FacebookUpload from "./components/FacebookUpload"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload } from "@fortawesome/free-solid-svg-icons"

function ImportBox() {
  const handleFbUploadClick = (e) => {
    e.preventDefault()
    document.getElementById("fb-popup-wrapper").style.display = "block"
  }

  const closePopUps = () => {
    const popUps = document.getElementsByClassName("popup-wrapper")
    for (let i = 0; i < popUps.length; i++) {
      popUps[i].style.display = "none"
    }
  }

  return (
    <div id="import-box">
      <button
        className="button-62 bigger"
        id="upload-fb-button"
        onClick={handleFbUploadClick}>
        <FontAwesomeIcon icon={faUpload} className="fa-big-button" />
        Importeer Facebook verjaardagen
      </button>
      <FacebookUpload closePopUps={closePopUps} />
    </div>
  )
}

export default ImportBox
