import React, { useContext, useEffect, useState, useCallback } from "react"
import "./BdPreview.css"
import rings from "./img/Rings_V2.png"
import zoomInImage from "./img/plus.png"
import zoomOutImage from "./img/min.png"

import CalenderPreview from "../CalenderPreview/CalenderPreview"

import AppContext from "../shared/AppContext"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons"

function BdPreview() {
  const {
    birthdayData,
    counter,
    languageTemp,
    activemonth,
    setActivemonth,
    template,
    fontSize,
    font,
    bg,
  } = useContext(AppContext)
  const [zoom, setZoom] = useState(4)

  const handleDoubleClickNu = (e) => {
    let inputId = "NM" + e.target.id.slice(0, -2)
    document.getElementById(inputId).select()
    document.getElementById(inputId).scrollIntoView()
  }

  const FillCalender = useCallback(() => {
    let allNames = document.getElementsByClassName("day-names")
    for (let i = 0; i < allNames.length; i++) {
      allNames[i].innerHTML = ""
    }
    // try {
    birthdayData.forEach((birthday) => {
      if (birthday.visible) {
        let dayblock = document.getElementById(
          `${birthday.month}-${birthday.day}`
        ).children[1]
        dayblock.insertAdjacentHTML(
          "beforeend",
          `<span class='name-unit' id=${birthday.id}nu>${birthday.name}</span>`
        )
      }
    })
    // } catch {}
  }, [birthdayData])

  useEffect(() => {
    FillCalender()
    try {
      document.querySelectorAll(".name-unit").forEach((item) => {
        item.addEventListener("dblclick", handleDoubleClickNu)
      })
    } catch {}
  }, [birthdayData, counter, FillCalender, zoom])

  //

  // Set starting values for the tempate
  useEffect(() => {
    let tempclass = document.getElementById("calender-preview")
    tempclass.classList.add(`temp_${template}`)
    tempclass.classList.add(`zoom_${zoom}`)
    tempclass.classList.add(`font_${font}`)
    tempclass.classList.add(`fontsize_${fontSize}`)
    tempclass.classList.add(`bg_${bg}`)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updateStylingHack = () => {
    document.getElementById("bd-preview").style.height = 0
    setTimeout(() => {
      document.getElementById("bd-preview").style.height = "100%"
    }, 0)
  }

  const zoomIn = () => {
    if (zoom !== 1) {
      setZoom(zoom - 1)

      let tempclass = document.getElementById("calender-preview")
      for (let i = 1; i < 10; i++) {
        tempclass.classList.remove(`zoom_${i}`)
      }
      tempclass.classList.add(`zoom_${zoom - 1}`)
    }
    updateStylingHack()
  }

  const zoomOut = () => {
    if (zoom !== 6) {
      setZoom(zoom + 1)

      let tempclass = document.getElementById("calender-preview")
      for (let i = 1; i < 10; i++) {
        tempclass.classList.remove(`zoom_${i}`)
      }
      tempclass.classList.add(`zoom_${zoom + 1}`)
    }
    updateStylingHack()
  }

  const slideMonths = (e) => {
    if (e.target.id === "back-button") {
      if (activemonth === 0) {
        setActivemonth(11)
      } else {
        setActivemonth(activemonth - 1)
      }
    } else {
      if (activemonth === 11) {
        setActivemonth(0)
      } else {
        setActivemonth(activemonth + 1)
      }
    }
  }

  return (
    <div id="bd-preview-wrapper">
      <div id="bd-preview" className="scrollbar-1">
        <div id="zoom-buttons">
          <button onClick={zoomIn} disabled={zoom === 1}>
            <img src={zoomInImage} alt="+" />
          </button>
          <button onClick={zoomOut} disabled={zoom === 6}>
            <img src={zoomOutImage} alt="-" />
          </button>
        </div>

        <button className="slide-button" id="back-button" onClick={slideMonths}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </button>
        <button
          className="slide-button"
          id="forward-button"
          onClick={slideMonths}>
          <FontAwesomeIcon icon={faCaretRight} />
        </button>

        <div id="calender-preview">
          <CalenderPreview
            activemonth={activemonth}
            setActivemonth={setActivemonth}
            languageTemp={languageTemp}
          />
          <img src={rings} id="ring-image" alt="" />
        </div>
      </div>
    </div>
  )
}

export default BdPreview
