import React, { Fragment } from "react"

import step1gif from "./img/Step_1.gif"
import step2gif from "./img/Step_2.gif"
import step3gif from "./img/Step_3.gif"

function UploadSlides() {
  return (
    <Fragment>
      <div className="upload-slide" id="upload-slide-1">
        <p>
          Installeer de Facebook Calendar Plugin voor Google Chrome of Microsoft
          Edge
          <br />
          <br />
          <a
            href="https://chrome.google.com/webstore/detail/birthday-calendar-exporte/imielmggcccenhgncmpjlehemlinhjjo"
            target="_blank"
            rel="noreferrer">
            <button className="button-62">Plugin toevoegen</button>
          </a>
          <br /> <br />
          <span className="smalltext">
            Heb je nog geen Google Chrome of Edge? Dan kan je de Chrome browser{" "}
            <a
              target="_blank"
              href="https://www.google.nl/intl/nl/chrome/"
              rel="noreferrer">
              hier
            </a>{" "}
            downloaden.
          </span>
        </p>
        <img src={step1gif} className="upload-gif" alt="" />
      </div>
      <div className="upload-slide" id="upload-slide-2">
        <p>
          Ga naar Facebook en open de Plugin.
          <br /> <br /> Klik op 'Scan Birthdays' en vervolgens op 'Start Scan'
          om de scan te starten.
          <br /> <br />
          <span className="smalltext">
            Als er een foutmelding komt over het lezen van een bepaalde maand
            kan je dit negeren.
          </span>
        </p>
        <img src={step2gif} className="upload-gif" alt="" />
      </div>
      <div className="upload-slide" id="upload-slide-3">
        <p>
          Download jouw verjaardagen als CSV bestand en upload het bestand via
          de knop 'Uploaden' hieronder.
        </p>
        <img src={step3gif} className="upload-gif" alt="" />
      </div>
    </Fragment>
  )
}

export default UploadSlides
