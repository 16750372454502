import React, { useContext } from "react"
import AppContext from "../shared/AppContext"
import "./Header.css"
import ImportBox from "../ImportBox/ImportBox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"

function Header() {
  const {
    font,
    setFont,
    template,
    setTemplate,
    setFontSize,
    fontSize,
    languageTemp,
    setLanguageTemp,
    bg,
    setBg,
    setPayPageOpen,
  } = useContext(AppContext)

  const openPay = () => {
    setPayPageOpen(true)
  }

  const changeTemplate = (e) => {
    setTemplate(e.target.value)
    // improve logic later
    let tempclass = document.getElementById("calender-preview")
    for (let i = 1; i < 5; i++) {
      tempclass.classList.remove(`temp_${i}`)
    }
    tempclass.classList.add(`temp_${e.target.value}`)
  }

  const changeBg = (e) => {
    setBg(e.target.value)
    let tempclass = document.getElementById("calender-preview")
    for (let i = 1; i < 5; i++) {
      tempclass.classList.remove(`bg_${i}`)
    }
    tempclass.classList.add(`bg_${e.target.value}`)
  }

  const changeFont = (e) => {
    setFont(e.target.value)
    let fontclass = document.getElementById("calender-preview")
    for (let i = 1; i < 20; i++) {
      fontclass.classList.remove(`font_${i}`)
    }
    fontclass.classList.add(`font_${e.target.value}`)
  }

  const changeFontSize = (e) => {
    setFontSize(e.target.value)
    let fontsizeclass = document.getElementById("calender-preview")
    for (let i = 1; i < 10; i++) {
      fontsizeclass.classList.remove(`fontsize_${i}`)
    }
    fontsizeclass.classList.add(`fontsize_${e.target.value}`)
  }

  const changeLanguage = (e) => {
    setLanguageTemp(e.target.value)
  }
  return (
    <div id="header">
      <ImportBox />
      <div>
        <button
          id="pdf-print-button"
          className="button-62 bigger"
          onClick={openPay}>
          <FontAwesomeIcon icon={faShoppingCart} className="fa-big-button" />
          Bestel kalender
        </button>

        <div id="preview-controls">
          <div>
            <div className="option-title">Template</div>
            <select
              value={template}
              onChange={changeTemplate}
              name="template"
              id="template-selector">
              <option value="1">Template 1</option>
              <option value="2">Template 2</option>
              <option value="3">Template 3</option>
              <option value="4">Template 4</option>
            </select>
            <select
              value={languageTemp}
              onChange={changeLanguage}
              name="language"
              id="language-selector">
              <option value="EN">English</option>
              <option value="NL">Nederlands</option>
            </select>
          </div>

          <div>
            <div className="option-title">Lettertype</div>
            <select
              value={font}
              onChange={changeFont}
              name="font"
              id="font-selector">
              <option value="1">Raleway</option>
              <option value="2">Roboto</option>
              <option value="3">Dancing Script</option>
              <option value="4">Times New Roman</option>
              <option value="5">Gill Sans</option>
              <option value="6">Ubuntu</option>
              <option value="7">Arial</option>
            </select>

            <select
              value={fontSize}
              onChange={changeFontSize}
              name="fontSize"
              id="fontSize-selector">
              <option value="1">6</option>
              <option value="2">7</option>
              <option value="3">8</option>
              <option value="4">9</option>
              <option value="5">10</option>
              <option value="6">11</option>
              <option value="7">12</option>
              <option value="8">13</option>
              <option value="9">14</option>
            </select>
          </div>
          <div>
            <div className="option-title">Achtergrond</div>
            <select value={bg} onChange={changeBg} name="bg" id="bg-selector">
              <option value="1">Geen</option>
              <option value="2">Ballonnen</option>
              <option value="3">Vlaggetjes</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
