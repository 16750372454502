import React, { useContext, useState, useEffect } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUpload,
  faCaretRight,
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons"

import AppContext from "../../shared/AppContext"
import "./uploadSlide.css"
import UploadSlides from "./UploadSlides.js"

import csv from "csv"

function FacebookUpload({ closePopUps }) {
  const { birthdayData, setBirthdayData, counter, setCounter } =
    useContext(AppContext)
  const [uploadStep, setUploadStep] = useState(1)

  useEffect(() => {
    document.querySelectorAll(".upload-slide").forEach((slide) => {
      if (slide.id === `upload-slide-${uploadStep}`) {
        slide.style.display = "flex"
      } else {
        slide.style.display = "none"
      }
    })
    document
      .querySelectorAll(".upload-progress-indicator")
      .forEach((indicator) => {
        if (indicator.id === `upload-progress-indicator-${uploadStep}`) {
          indicator.style.backgroundColor = "rgb(45,45,45)"
        } else {
          indicator.style.backgroundColor = "rgba(0,0,0,0)"
        }
      })
  }, [uploadStep])

  const restartGifs = () => {
    document.querySelectorAll(".upload-gif").forEach((element) => {
      if (element) {
        var imgSrc = element.src
        element.src = imgSrc
      }
    })
  }

  const stepFoward = () => {
    setUploadStep(uploadStep + 1)
    restartGifs()
  }

  const stepBack = () => {
    setUploadStep(uploadStep - 1)
    restartGifs()
  }

  const handleFileChange = (e) => {
    const reader = new FileReader()
    reader.onload = function () {
      csv.parse(reader.result, (err, data) => {
        try {
          const data2 = data.map((row, index) => {
            let row2 = {
              id: `${index}u`,
              visible: true,
              name: decodeURIComponent(escape(row[0])),
              month: parseInt(row[2]),
              day: parseInt(row[3]),
              url: row[4],
            }

            return row2
          })

          let newBirthdayData = birthdayData
          newBirthdayData.push(...data2.slice(1))
          setBirthdayData(newBirthdayData)
          setCounter(counter + 1)
        } catch {
          alert(
            "Bestandstype wordt niet ondersteund, upload een CSV export van de Facebook Calendar Plugin"
          )
        }
      })
    }

    reader.readAsBinaryString(e.target.files[0])
    closePopUps()
    document.getElementById("file-upload").value = ""
  }

  return (
    <div className="popup-wrapper" id="fb-popup-wrapper">
      <div className="popup-background" onClick={closePopUps}></div>
      <div id="facebook-upload-popup" onClick={() => {}}>
        <div className="close-popup" onClick={closePopUps}>
          X
        </div>
        <p>Download een CSV bestand in drie stappen en upload hem hieronder</p>
        <div id="upload-slide-wrapper">
          <button
            style={{ display: uploadStep === 3 ? "none" : "block" }}
            className="slide-button2"
            id="upload-next-btn"
            onClick={stepFoward}>
            <FontAwesomeIcon icon={faCaretRight} />
          </button>
          <button
            style={{ display: uploadStep === 1 ? "none" : "block" }}
            className="slide-button2"
            id="upload-prev-btn"
            onClick={stepBack}>
            <FontAwesomeIcon icon={faCaretLeft} />
          </button>
          <div id="upload-progress-indicators">
            <div
              className="upload-progress-indicator"
              id="upload-progress-indicator-1"></div>
            <div
              className="upload-progress-indicator"
              id="upload-progress-indicator-2"></div>
            <div
              className="upload-progress-indicator"
              id="upload-progress-indicator-3"></div>
          </div>
          <UploadSlides />
        </div>
        {/* <button className="button-62 bigger" id="upload-button"> */}
        <label
          htmlFor="file-upload"
          className="button-62 bigger"
          id="upload-button">
          <FontAwesomeIcon icon={faUpload} className="fa-custom" /> Uploaden
          <input onChange={handleFileChange} id="file-upload" type="file" />
        </label>
        {/* </button> */}
      </div>
    </div>
  )
}

export default FacebookUpload
