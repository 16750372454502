import React, { useContext, useState, useEffect } from "react"
import "./PayPage.css"

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import {} from "@fortawesome/free-solid-svg-icons"

import AppContext from "../shared/AppContext"

function PayPage() {
  const {
    birthdayData,
    template,
    languageTemp,
    font,
    fontSize,
    bg,
    payPageOpen,
    setPayPageOpen,
  } = useContext(AppContext)
  const [payId, setPayId] = useState("")

  const closePayPage = () => {
    setPayPageOpen(false)
    const popUps = document.getElementsByClassName("popup-wrapper")
    for (let i = 0; i < popUps.length; i++) {
      popUps[i].style.display = "none"
    }
  }

  const saveDataAndContinue = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        template: template,
        templateLanguage: languageTemp,
        font: font,
        fontSize: fontSize,
        bg: bg,
        birthdayData: birthdayData,
      }),
    }
    fetch("http://localhost:5000/api/pdf/startcheckout", requestOptions)
      .then((response) => response.json())
      .then((data) => setPayId(data.id))
  }

  useEffect(() => {
    if (payPageOpen) {
      document.getElementById("paypage-popup-wrapper").style.display = "block"
    }
  }, [payPageOpen])

  return (
    <div className="popup-wrapper" id="paypage-popup-wrapper">
      <div className="popup-background"></div>
      <div id="facebook-upload-popup">
        <div className="close-popup" onClick={closePayPage}>
          X
        </div>
        <p>
          Make sure you're done editing before continuing to checkout.
          <br />
          Are you done editing your custom calendar?
        </p>
        <div id="sure-buttons-wrapper">
          <button
            className="button-62 bigger"
            id="upload-button"
            onClick={closePayPage}>
            No, continue editing
          </button>
          <button
            className="button-62 bigger"
            id="upload-button"
            onClick={saveDataAndContinue}>
            Yes, continue checkout
          </button>
        </div>
      </div>
    </div>
  )
}

export default PayPage
