import React, { useEffect, useContext } from "react"
import "./CalenderPreview.css"
import "./Template_styles.css"

import AppContext from "../shared/AppContext"

function CalenderPreview({ activemonth, languageTemp }) {
  const { months, monthsNL, monthLengths } = useContext(AppContext)
  let dayComponents = []

  monthLengths.forEach((length, monthindex) => {
    const monthDayArray = Array.from(Array(length).keys())

    dayComponents[monthindex] = monthDayArray.map((day) => {
      return (
        <div
          className="day-block"
          key={day}
          id={`${monthindex + 1}-${day + 1}`}>
          <div className="day-number">{day + 1}</div>
          <div className="day-names"></div>
        </div>
      )
    })
  })

  const monthComponents = months.map((month, index) => {
    return (
      <div key={index} className="page-wrapper" id={`month-${index}`}>
        <div className="month-title">
          <h2>{languageTemp === "NL" ? monthsNL[index] : month}</h2>
        </div>
        <div className="day-tiles">{dayComponents[index]}</div>
      </div>
    )
  })

  useEffect(() => {
    const monthComponents = document.getElementsByClassName("page-wrapper")
    for (let i = 0; i < monthComponents.length; i++) {
      monthComponents[i].style.display = "none"
    }
    monthComponents[activemonth].style.display = "block"
  }, [activemonth])

  return <div id="section-to-print">{monthComponents}</div>
}

export default CalenderPreview
