import React, { useEffect } from "react"

function SmallScreenWarning() {
  const closeSmallScreenWarning = () => {
    const popUps = document.getElementsByClassName("popup-wrapper")
    for (let i = 0; i < popUps.length; i++) {
      popUps[i].style.display = "none"
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      document.getElementById("smallscreen-popup-wrapper").style.display =
        "block"
    }
  }, [])

  return (
    <div className="popup-wrapper" id="smallscreen-popup-wrapper">
      <div className="popup-background"></div>
      <div id="facebook-upload-popup">
        <div className="close-popup" onClick={closeSmallScreenWarning}>
          X
        </div>
        <p>
          Deze tool werkt het beste op een laptop of pc. Om jouw Facebook
          verjaardagen te importeren moet je op een laptop of pc werken.
        </p>
        <div id="sure-buttons-wrapper">
          <button
            className="button-62 bigger"
            id="upload-button"
            onClick={closeSmallScreenWarning}>
            Tool toch openen
          </button>
        </div>
      </div>
    </div>
  )
}

export default SmallScreenWarning
