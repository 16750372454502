import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import "../../App.css"
import "../../components/shared/resets.css"
import "../../Print.css"
import BdPreview from "../../components/BdPreview/BdPreview"
import AppContext from "../../components/shared/AppContext"

function App() {
  const {
    templateParam,
    languageParam,
    fontParam,
    fontSizeParam,
    bgParam,
    orderId,
  } = useParams()
  const [counter, setCounter] = useState(0)
  const monthLengths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const monthsNL = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ]

  /* Set birthdaydata */
  const [birthdayData, setBirthdayData] = useState([])

  /* Controls */
  // Controls are taken from the params values
  const [template, setTemplate] = useState(templateParam)
  const [font, setFont] = useState(fontParam)
  const [fontSize, setFontSize] = useState(fontSizeParam)
  const [bg, setBg] = useState(bgParam)
  const [languageTemp, setLanguageTemp] = useState(languageParam)
  const [activemonth, setActivemonth] = useState(0)

  // Get the birthday data from the backend using the orderId
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        orderId: orderId,
      }),
    }
    fetch("http://localhost:5000/api/pdf/getdata", requestOptions)
      .then((response) => response.json())
      .then((data) => setBirthdayData(data))
    setCounter(counter + 1)
    // eslint-disable-next-line
  }, [])

  return (
    <AppContext.Provider
      value={{
        birthdayData: birthdayData,
        setBirthdayData: setBirthdayData,
        counter,
        setCounter,
        months,
        monthsNL,
        monthLengths,
        template,
        setTemplate,
        font,
        setFont,
        fontSize,
        setFontSize,
        languageTemp,
        setLanguageTemp,
        activemonth,
        setActivemonth,
        bg,
        setBg,
      }}>
      <div id="pdf-endpoint">
        {/* <Header /> */}
        <div id="page-wrapper">
          <div id="side-menu">{/* <BdList /> */}</div>
          <BdPreview />
        </div>
      </div>
    </AppContext.Provider>
  )
}

export default App
