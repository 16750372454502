import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "./index.css"
import App from "./App"
import PdfEndpoint from "./components/PdfEndpoint/PdfEndpoint"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* The actual application */}
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
      {/* The endpoint used by puppeteer in the backend, not to be used by endusers*/}
      <Routes>
        <Route
          path="/pdf_endpoint/:templateParam/:languageParam/:fontParam/:fontSizeParam/:bgParam/:orderId"
          element={<PdfEndpoint />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)
