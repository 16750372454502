import React, { useState, useContext, useEffect } from "react"
import AppContext from "../../shared/AppContext"
import "./NewBirthday.css"

function NewBirthday() {
  const {
    monthsNL,
    monthLengths,
    birthdayData,
    setBirthdayData,
    setCounter,
    counter,
  } = useContext(AppContext)
  const [name, setName] = useState("")
  const [day, setDay] = useState(1)
  const [month, setMonth] = useState(1)
  const [maxDay, setMaxDay] = useState(31)
  const [newCounter, setNewCounter] = useState(1)

  useEffect(() => {
    setMaxDay(monthLengths[month - 1])
  }, [month, monthLengths])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDayChange = (e) => {
    setDay(e.target.value)
  }

  const handleMonthChange = (e) => {
    setMonth(e.target.value)
  }

  const handleAddBirthday = (e) => {
    e.preventDefault()
    let valid = document.getElementById("new-birthday-form").checkValidity()
    document.getElementById("new-birthday-form").reportValidity()
    if (valid) {
      let newBirthday = {
        id: `${newCounter}m`,
        visible: true,
        name: name,
        month: month,
        day: day,
      }
      let newBirthdayData = birthdayData
      newBirthdayData.unshift(newBirthday)
      setBirthdayData(newBirthdayData)
      setCounter(counter + 1)
      setName("")
      setMonth(1)
      setDay(1)
      setNewCounter(newCounter + 1)
    }
  }

  const monthOptions = monthsNL.map((month, index) => {
    return (
      <option key={index} value={index + 1}>
        {month.toLowerCase()}
      </option>
    )
  })

  return (
    <div id="new-birthday">
      <form id="new-birthday-form">
        <div id="first-row-new-birthday">
          <input
            type="text"
            id={`NMnew`}
            value={name}
            minLength="1"
            placeholder="Voeg naam toe"
            onChange={handleNameChange}
          />
          <input
            type="number"
            min={1}
            max={maxDay}
            value={day}
            onChange={handleDayChange}
            id="new-day"
          />
          <select
            value={month}
            onChange={handleMonthChange}
            name="template"
            id="new-month">
            {monthOptions}
          </select>
        </div>
        <button
          type="submit"
          id="add-new-button"
          className="button-62"
          onClick={handleAddBirthday}
          disabled={name.length < 1}>
          Toevoegen
        </button>
      </form>
    </div>
  )
}

export default NewBirthday
