import React, { useState } from "react"

// Import stylesheets
import "./App.css"
import "./components/shared/resets.css"
import "./components/shared/buttons.css"
import "./components/shared/scrollbar.css"

// Import the componenets
import BdList from "./components/BdList/BdList.js"
import BdPreview from "./components/BdPreview/BdPreview"
import Header from "./components/Header/Header"
import AppContext from "./components/shared/AppContext"
import PayPage from "./components/PayPage/PayPage"
import SmallScreenWarning from "./components/SmallScreenWarning/SmallScreenWarning"
import CookieConsent from "react-cookie-consent"

function App() {
  // counter is used to cue each update of the birthdayData array
  const [counter, setCounter] = useState(0)
  const monthLengths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const monthsNL = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ]

  /* Set birthdaydata */
  const [birthdayData, setBirthdayData] = useState([])

  /* Design controls */
  // The starting values of the controls are set here
  const [template, setTemplate] = useState(1)
  const [font, setFont] = useState(1)
  const [fontSize, setFontSize] = useState(5)
  const [bg, setBg] = useState(2)
  const [languageTemp, setLanguageTemp] = useState("NL")

  /* Display controls */
  const [activemonth, setActivemonth] = useState(0)
  const [payPageOpen, setPayPageOpen] = useState(false)

  return (
    <AppContext.Provider
      value={{
        birthdayData: birthdayData,
        setBirthdayData: setBirthdayData,
        counter,
        setCounter,
        months,
        monthsNL,
        monthLengths,
        template,
        setTemplate,
        font,
        setFont,
        fontSize,
        setFontSize,
        languageTemp,
        setLanguageTemp,
        activemonth,
        setActivemonth,
        bg,
        setBg,
        payPageOpen,
        setPayPageOpen,
      }}>
      <SmallScreenWarning />
      <Header />
      <PayPage />
      <div id="page-wrapper">
        <BdList />
        <BdPreview />
      </div>

      <CookieConsent
        location="bottom"
        buttonText="Understood"
        style={{ background: "rgb(94,94,94,0.95)" }}
        buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
        expires={150}>
        This website uses cookies to enhance the user experience
      </CookieConsent>
    </AppContext.Provider>
  )
}

export default App
