import React, { useContext, useEffect, useState, useCallback } from "react"
import AppContext from "../shared/AppContext"
import NewBirthday from "./components/NewBirthday"

import "./BdList.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/fontawesome-free-brands"

function BdList() {
  const {
    birthdayData,
    setBirthdayData,
    counter,
    setCounter,
    // months,
    monthsNL,
  } = useContext(AppContext)
  const [birthdayComponents, setBirthdayComponents] = useState()
  const [searchValue, setSearchValue] = useState()
  const [selectBool, setSelectBool] = useState(false)

  const handleChange = (e) => {
    let newBirthdayData = birthdayData
    const birthdayIndex = newBirthdayData.findIndex((obj) => {
      return obj.id === e.target.id.slice(2)
    })
    if (e.target.id.slice(0, 2) === "CB") {
      newBirthdayData[birthdayIndex].visible =
        !birthdayData[birthdayIndex].visible
    } else if (e.target.id.slice(0, 2) === "NM") {
      newBirthdayData[birthdayIndex].name = e.target.value
    }
    setBirthdayData(newBirthdayData)
    setCounter(counter + 1)
  }

  const handleRemove = (e) => {
    let newBirthdayData = birthdayData.filter((item) => {
      return item.id !== e.target.id.slice(2)
    })
    setBirthdayData(newBirthdayData)
    setCounter(counter + 1)
  }

  const handleSelectAll = (bool) => {
    let newBirthdayData = birthdayData
    newBirthdayData.forEach((birthday) => {
      birthday.visible = bool
    })
    setBirthdayData(newBirthdayData)
    setSelectBool(!selectBool)
    setCounter(counter + 1)
  }

  const handleDeleteAll = () => {
    let newBirthdayData = []
    setBirthdayData(newBirthdayData)
    setCounter(counter + 1)
  }

  function sortFunction(a, b) {
    if (a.month + 0.01 * a.day === b.month + 0.01 * b.day) {
      return 0
    } else {
      return a.month + 0.01 * a.day < b.month + 0.01 * b.day ? -1 : 1
    }
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }

  const eraseSearch = () => {
    setSearchValue("")
  }

  const checkSearch = (searchValue, name) => {
    if (name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
      return true
    } else {
      return false
    }
  }

  const updateBirthdays = useCallback(() => {
    if (birthdayData.length > 0) {
      let birthdayData_sorted = birthdayData.sort(sortFunction)
      const birthdayComponentsUseEffect = birthdayData_sorted.map(
        (birthday, index) => {
          let searchResult = true
          if (searchValue) {
            const splitSearch = searchValue.split(" ")

            splitSearch.forEach((splitSearch) => {
              if (!checkSearch(splitSearch.trim(), birthday.name)) {
                searchResult = false
              }
            })
          }
          if (searchResult) {
            return (
              <div key={index} id="" className="list-item-birthday">
                <input
                  type="checkbox"
                  id={`CB${birthday.id}`}
                  checked={birthday.visible}
                  onChange={handleChange}
                  className="checkbox-custom"
                />
                <input
                  type="text"
                  id={`NM${birthday.id}`}
                  value={birthday.name}
                  onChange={handleChange}
                />{" "}
                <div className="fa-wrapper">
                  {birthday.url ? (
                    <a href={birthday.url} target="_blank" rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        className="facebook-icon"
                      />
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                <div className="list-date">
                  {birthday.day} {monthsNL[birthday.month - 1].toLowerCase()}
                </div>
                <button
                  className="list-remove-button"
                  id={`DE${birthday.id}`}
                  onClick={handleRemove}>
                  X
                </button>
              </div>
            )
          } else {
            return ""
          }
        }
      )
      setBirthdayComponents(birthdayComponentsUseEffect)
    } else {
      setBirthdayComponents()
    }
  }, [counter, searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateBirthdays()
  }, [birthdayData, counter, updateBirthdays])

  return (
    <div id="side-menu">
      {/* <h2 className="h2-title">Select {"&"} add birthdays</h2> */}

      <NewBirthday />
      <div id="bdlist-UI">
        <div id="bdlist-option-button-wrapper">
          <button
            className="bdlist-option-button button-62"
            onClick={() => handleSelectAll(selectBool)}>
            (de)selecteer alles
          </button>
          {/* <button
            className="bdlist-option-button button-62"
            onClick={() => handleSelectAll(false)}>
            Deselecteer alles
          </button> */}
          <button
            className="bdlist-option-button button-62"
            onClick={handleDeleteAll}>
            verwijder alles
          </button>
        </div>
        <div id="search-wrapper">
          <input
            placeholder="Zoek namen..."
            type="text"
            id="search-box"
            value={searchValue}
            onChange={handleSearch}
          />
          <div id="x-icon" onClick={eraseSearch}>
            X
          </div>
        </div>

        <div id="bd-list-container" className="scrollbar-1">
          {birthdayComponents ? (
            birthdayComponents
          ) : (
            <div id="no-birthdays">
              Er zijn nog geen verjaardagen geïmporteerd
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BdList
